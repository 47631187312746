<template>
  <div>
    <b-row align-h="end">
      <b-col v-if="!value.default && !disabled" cols="auto">
        <b-link class="mr-1 cursor-pointer" @click="$emit('clone-element')">
          <feather-icon icon="CopyIcon" size="20" />
        </b-link>
        <b-link class="mr-1 cursor-pointer" @click="$emit('order-up')">
          <feather-icon icon="ArrowUpIcon" size="20" />
        </b-link>
        <b-link class="mr-1 cursor-pointer" @click="$emit('order-down')">
          <feather-icon icon="ArrowDownIcon" size="20" />
        </b-link>
        <b-link
          class="mr-1 text-danger cursor-pointer"
          @click="$emit('remove-element')"
        >
          <feather-icon icon="TrashIcon" size="20" />
        </b-link>
      </b-col>
      <b-col v-if="value.default" cols="auto">
        <p>Default</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group label-for="select-type" :label="$t('SelectTypeAnswer')">
          <CustomFormElementsSelect
            id="select-type"
            :disabled="value.default || disabled"
            :value="elementType"
            @input="handleAliasChange"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          :label="$t('Pregunta')"
          label-for="custom-form-element-question"
        >
          <b-form-input
            id="custom-form-element-question"
            :value="value.config ? value.config.question : ''"
            :disabled="value.default || disabled"
            :placeholder="$t('Pregunta')"
            @input="$emit('input', { ...value, config: { ...value.config, question: $event } })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <component
          :is="value.alias"
          :value="value.config"
          :disabled="value.default || disabled"
          @input="$emit('input', { ...value, config: $event })"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CustomFormElementsSelect from "@/components/customForms/select/CustomFormElementsSelect.vue";
import CustomFormRadioElement from "@/components/customForms/element/CustomFormRadioElement.vue";
import CustomFormCheckboxElement from "@/components/customForms/element/CustomFormCheckboxElement.vue";
import CustomFormTextAreaElement from "@/components/customForms/element/CustomFormTextAreaElement.vue";
import CustomFormSignatureElement from "@/components/customForms/element/CustomFormSignatureElement.vue";
import CustomFormFilesElement from "@/components/customForms/element/CustomFormFilesElement.vue";
import CustomFormSelectElement from "@/components/customForms/element/CustomFormSelectElement.vue";
import CustomFormHiddenElement from "@/components/customForms/element/CustomFormHiddenElement.vue";
import CustomFormTitleElement from "@/components/customForms/element/CustomFormTitleElement.vue";
import CustomFormTextBlockElement from "@/components/customForms/element/CustomFormTextBlockElement.vue";
import { mapGetters } from 'vuex'

export default {
  name: "CustomFormElement",
  components: {
    CustomFormElementsSelect,
    radio: CustomFormRadioElement,
    check: CustomFormCheckboxElement,
    text_area: CustomFormTextAreaElement,
    signature: CustomFormSignatureElement,
    archive: CustomFormFilesElement,
    hidden: CustomFormHiddenElement,
    select_input: CustomFormSelectElement,
    title_input: CustomFormTitleElement,
    text_input: CustomFormTextAreaElement,
    text_block: CustomFormTextBlockElement,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
        role: 'auth/getRole',
    }),
    elementType() {
      return {
        id: this.value.id,
        alias: this.value.alias,
        name: this.value.name,
        default: this.value.default,
      };
    },
  },
  methods: {
    handleAliasChange(elementType) {
      
      const config = this.value.config || {}

      if (elementType.alias !== 'check') {
        delete config.options
      }
      
      if(this.role === 'admin_cliente') {
        config.showpdf = true
      }

      this.$emit("input", {
        ...this.value,
        id: elementType.id,
        alias: elementType.alias,
        name: elementType.name,
        config
      });
    },
  },
};
</script>
