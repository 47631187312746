<template>
  <validation-observer ref="custom-form-profile-form">
    <h4 class="mb-0">
      {{ $t('PerfilFormulario') }}
    </h4>
    <b-form class="p-1">
      <b-row>
        <b-col sm="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('Nombre')"
            rules="required"
          >
            <b-form-group
              :label="$t('Nombre')"
              label-for="form-name"
            >
              <b-form-input
                id="form-name"
                :value="value.name"
                :placeholder="$t('Nombre')"
                @input="update('name', $event)"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Estado')"
            label-for="form-active"
          >
            <v-select
              id="form-active"
              :value="value.active"
              :filterable="true"
              :searchable="true"
              label="text"
              :reduce="(item => item.value)"
              :options="selectStatus"
              :placeholder="$t('Desactivado')"
              @input="update('active', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="role !== 'admin_cliente'" sm="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('Eventos')"
            rules="required"
          >
            <b-form-group
              :label="$t('Eventos')"
              label-for="form-events"
            >
              <v-select
                id="form-events"
                :value="value.events"
                label="name"
                :filterable="true"
                :searchable="true"
                :options="selectEvents"
                :clearable="false"
                multiple
                @input="update('events', $event)"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col v-show="role !== 'admin_cliente'" sm="6">
          <b-form-group
            :label="$t('Compañía')"
            label-for="form-company"
          >
            <v-select
              id="form-company"
              :value="value.company"
              label="name"
              :filterable="false"
              :searchable="false"
              :options="selectCompanies"
              @input="update('company', $event)"
              @option:selected="handleCompanySelect"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="role !== 'admin_cliente'" sm="6">
          <b-form-group
            :label="$t('Cliente')"
            label-for="form-clients"
          >
            <v-select
              id="form-clients"
              :value="value.client"
              label="name"
              :filterable="true"
              :searchable="true"
              :options="selectClients"
              @input="update('client', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Descripcion')"
          >
            <b-form-group
              :label="$t('Descripcion')"
              label-for="form-description"
            >
              <b-textarea
                id="form-description"
                :value="value.description"
                @input="update('description', $event)"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CustomFormProfileForm',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectStatus: [
        { text: this.$t('Desactivado'), value: 0 },
        { text: this.$t('Activado'), value: 1 },
      ],
    }
  },
  computed: {
    ...mapGetters({
      selectCompanies: 'companies/getListCompanies',
      selectClients: 'clients/getSelectClients',
      selectEvents: 'events/getItems',
      role: 'auth/getRole',
    }),
    company() {
      return this.value.company
    },
  },
  watch: {
    company: {
      deep: true,
      handler(oldValue, newValue) {
        if (oldValue?.id === newValue?.id) {
          return
        }

        this.getClients()
      },
    },
  },
  methods: {
    ...mapActions({
      getSelectCompanies: 'companies/searchCompanies',
      getSelectClients: 'clients/selectClients',
      getSelectEvents: 'events/getList',
    }),
    validate() {
      return this.$refs['custom-form-profile-form'].validate()
    },
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    handleCompanySelect(company) {
      this.$emit('input', {
        ...this.value,
        client: '',
        company,
      })
    },
    getClients() {
      if (!this.value.company?.id) {
        return Promise.resolve(null)
      }

      return this.getSelectClients(this.value.company.id)
    },
  },
  async mounted() {
    await Promise.all([
      this.getSelectCompanies(),
      this.getSelectEvents(),
    ])
  },
}
</script>

<style scoped>
</style>
